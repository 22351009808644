import React, { FunctionComponent } from 'react';

import { Button } from "../ui/button";
import { useTranslation } from "../../TranslationContext";
import star from "../../assets/svg/star-filled.svg";
import google from "../../assets/svg/google.svg";

const Testimonials: FunctionComponent = () => {
    const translations = useTranslation();
    return (
        <div id="testimonials"
            className="w-full relative bg-white overflow-hidden flex flex-col items-center justify-start py-24 px-10 box-border text-center text-4xl text-nebula-950 font-text-base-font-medium lg:px-20 md:py-16 md:px-10">
            <div className="w-full flex flex-col items-center justify-start gap-16 max-w-[1280px]">
                <div className="self-stretch flex flex-col items-start justify-start gap-4">
                    <div className="self-stretch relative tracking-[-0.01em] leading-[125%] font-extrabold">{translations.testimonials.title}</div>
                    <div className="self-stretch relative text-xl leading-[150%] text-slate-500">{translations.testimonials.description}</div>
                </div>
                <div className="self-stretch flex flex-row items-center justify-between text-left text-sm text-slate-800 lg:flex-col-reverse lg:gap-16">
                    <div className="flex-1 flex flex-row items-start justify-center gap-8">
                        <div className="flex-1 flex flex-col items-start justify-start gap-8 max-w-[397px] xl:max-w-[300px] lg:w-full lg:max-w-full">
                            <div className="self-stretch rounded-xl bg-slate-50 flex flex-col items-start justify-start px-6 py-6 gap-5 ">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-col items-start justify-start">
                                        <div className="self-stretch relative leading-[150%] font-medium">"{translations.testimonials.reviews[0].text}"</div>
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-row items-start justify-end gap-4 text-slate-500">
                                    <div className="flex-1 flex flex-col items-end justify-start">
                                        <div className="self-stretch relative leading-[125%]">{translations.testimonials.reviews[0].author}</div>
                                    </div>
                                    <div className="flex flex-row items-center justify-center gap-0.5">
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch rounded-xl bg-slate-50 flex flex-col items-start justify-start px-6 py-6 gap-5">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-col items-start justify-start">
                                        <div className="self-stretch relative leading-[150%] font-medium">"{translations.testimonials.reviews[1].text}"</div>
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-row items-start justify-end gap-4 text-slate-500">
                                    <div className="flex-1 flex flex-col items-end justify-start">
                                        <div className="self-stretch relative leading-[125%]">{translations.testimonials.reviews[1].author}</div>
                                    </div>
                                    <div className="flex flex-row items-center justify-center gap-0.5">
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch rounded-xl bg-slate-50 flex flex-col items-start justify-start px-6 py-6 gap-5">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-col items-start justify-start">
                                        <div className="self-stretch relative leading-[150%] font-medium">"{translations.testimonials.reviews[2].text}"</div>
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-row items-start justify-end gap-4 text-slate-500">
                                    <div className="flex-1 flex flex-col items-end justify-start">
                                        <div className="self-stretch relative leading-[125%]">{translations.testimonials.reviews[2].author}</div>
                                    </div>
                                    <div className="flex flex-row items-center justify-center gap-0.5">
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex-1 flex flex-col items-start justify-start gap-8 max-w-[397px] xl:max-w-[300px] lg:w-full lg:max-w-full sm:hidden">
                            <div className="self-stretch rounded-xl bg-slate-50 flex flex-col items-start justify-start px-6 py-6 gap-5">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-col items-start justify-start">
                                        <div className="self-stretch relative leading-[150%] font-medium">"{translations.testimonials.reviews[3].text}"</div>
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-row items-start justify-end gap-4 text-slate-500">
                                    <div className="flex-1 flex flex-col items-end justify-start">
                                        <div className="self-stretch relative leading-[125%]">{translations.testimonials.reviews[3].author}</div>
                                    </div>
                                    <div className="flex flex-row items-center justify-center gap-0.5">
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch rounded-xl bg-slate-50 flex flex-col items-start justify-start px-6 py-6 gap-5">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-col items-start justify-start">
                                        <div className="self-stretch relative leading-[150%] font-medium">"{translations.testimonials.reviews[4].text}"</div>
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-row items-start justify-end gap-4 text-slate-500">
                                    <div className="flex-1 flex flex-col items-end justify-start">
                                        <div className="self-stretch relative leading-[125%]">{translations.testimonials.reviews[4].author}</div>
                                    </div>
                                    <div className="flex flex-row items-center justify-center gap-0.5">
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                    </div>
                                </div>
                            </div>
                            <div className="self-stretch rounded-xl bg-slate-50 flex flex-col items-start justify-start px-6 py-6 gap-5">
                                <div className="self-stretch flex flex-col items-start justify-start">
                                    <div className="self-stretch flex flex-col items-start justify-start">
                                        <div className="self-stretch relative leading-[150%] font-medium">"{translations.testimonials.reviews[5].text}"</div>
                                    </div>
                                </div>
                                <div className="self-stretch flex flex-row items-start justify-end gap-4 text-slate-500">
                                    <div className="flex-1 flex flex-col items-end justify-start">
                                        <div className="self-stretch relative leading-[125%]">{translations.testimonials.reviews[5].author}</div>
                                    </div>
                                    <div className="flex flex-row items-center justify-center gap-0.5">
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                        <img className="w-4 relative h-4 overflow-hidden shrink-0" alt="" src={star} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="max-w-[390px] rounded-2xl bg-white border-nebula-500 border-solid border-[2px] box-border overflow-hidden shrink-0 flex flex-col items-center justify-center py-16 px-20 xl:px-12 gap-10 lg:w-full lg:max-w-full text-center text-7xl text-slate">
                        <img className="w-16 relative h-16 overflow-hidden shrink-0" alt="" src={google} />
                        <img className="w-[181.9px] relative h-[77.3px] overflow-hidden shrink-0 hidden" alt="" src="Brand logos/Google.svg" />
                        <div className="self-stretch flex flex-col items-start justify-start gap-5">
                            <div className="self-stretch flex flex-col items-center justify-start">
                                <b className="self-stretch relative leading-[125%]">5.0</b>
                                <div className="flex flex-row items-center justify-center gap-1">
                                    <img className="w-8 relative h-8 overflow-hidden shrink-0" alt="" src={star} />
                                    <img className="w-8 relative h-8 overflow-hidden shrink-0" alt="" src={star} />
                                    <img className="w-8 relative h-8 overflow-hidden shrink-0" alt="" src={star} />
                                    <img className="w-8 relative h-8 overflow-hidden shrink-0" alt="" src={star} />
                                    <img className="w-8 relative h-8 overflow-hidden shrink-0" alt="" src={star} />
                                </div>
                            </div>
                            <div className="self-stretch relative text-2xl leading-[125%] text-slate-600">{translations.testimonials.googleReviews.text}</div>
                        </div>
                        <Button
                        variant="outline"
                        size="xl"
                        onClick={() => window.open('https://www.google.com/search?sca_esv=86853dcde29fa618&q=Nexprim+-+Textile+Printing&si=APYL9bs7Hg2KMLB-4tSoTdxuOx8BdRvHbByC_AuVpNyh0x2Kzf5AJblW69Jp1l_DtUsv6oKaDrVgM_1A29MySLjXFlKQmWV9pFXBgCRv8NzHc2soG_3zJhA%3D&uds=ABqPDvyK2YNDrMNEiYeR7Rqrb_XxnrK8TVajXI-K3gEPMrRLPbeczBy10KuXaGzKhmjEWjrno8imSGsM8YZlHAGpD72Swxahex_-JA04mEW-EeuJ03KgVmIc-lGwkEttzfnnDJb3uwAY&sa=X&ved=2ahUKEwiPj7m4x_yLAxUI0wIHHWwSDnQQ3PALegQIDxAE&biw=2560&bih=1277&dpr=1.5', '_blank')}
                        >
                        {translations.testimonials.googleReviews.button}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonials;