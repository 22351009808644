import { FunctionComponent } from "react";

import img_1 from "../../assets/png/prod_photo_1.png";
import img_2 from "../../assets/png/prod_photo_2.png";
import img_3 from "../../assets/png/prod_photo_3.png";
import img_4 from "../../assets/png/prod_photo_4.png";
import img_5 from "../../assets/png/prod_photo_5.png";
import img_6 from "../../assets/png/prod_photo_6.png";



const Gallery: FunctionComponent = () => {
  return (
    <div className="w-full relative bg-slate-950 flex flex-col items-center justify-center py-24 box-border text-center text-4xl md:text-3xl text-white px-16 sm:px-10">
      <div className="w-full relative bg-slate-950 flex flex-col items-center justify-center max-w-[1280px] gap-10">
        <div className="self-stretch flex flex-col items-center justify-center">
          <div className="self-stretch flex flex-col items-center justify-center gap-10">
            <div className="self-stretch relative leading-[150%] font-extrabold">Nahliadnite do zákulisia výroby</div>
            <div className="w-full relative leading-[150%] text-slate-200 inline-block max-w-[1000px] text-base text-slate-200 ">Pozrite sa, ako vznikajú vaše produkty. Precízna prípráca, starostlivá kontrola a profesionálna realizácia – toto všetko stojí za každým výrobkom, ktorý sa dostane až k vám. Každý kus vyrábame s maximálnou starostlivosťou.</div>
          </div>
        </div>
        <div className="self-stretch flex flex-row items-center justify-center gap-10">
          <div className="self-stretch flex flex-row items-center justify-center gap-10 md:flex-col">
            <div className="flex-1 flex flex-col items-center justify-start gap-10">
              <img className="flex-1 relative rounded-2xl overflow-hidden max-h-full object-cover" alt="" src={img_1} />
              <img className="flex-1 relative rounded-2xl overflow-hidden max-h-full object-cover" alt="" src={img_2} />
            </div>
            <div className="flex-1 flex flex-col items-center justify-start gap-10">
              <img className="flex-1 relative rounded-2xl overflow-hidden max-h-full object-cover" alt="" src={img_3} />
              <img className="flex-1 relative rounded-2xl overflow-hidden max-h-full object-cover" alt="" src={img_4} />
            </div>
            <div className="flex-1 flex flex-col items-center justify-start gap-10 md:hidden">
              <img className="flex-1 relative rounded-2xl overflow-hidden max-h-full object-cover" alt="" src={img_5} />
              <img className="flex-1 relative rounded-2xl overflow-hidden max-h-full object-cover" alt="" src={img_6} />
            </div>
          </div>
        </div>
      </div>
    </div >);
};

export default Gallery;
